import React, {useState} from 'react'; //useState 함수 사용 가능
import logo from './logo.svg';
import './App.scss';

//컴포넌트를 쓰면 좋을 때
//1. 반복적인 html을 축약할 때
//2. 큰 페이지
//3. 자주 수정되는 UI
// 컴포넌트 안에는 html을 병렬로 쓰러면 div를 하나로 감싸야 한다.
// 의미없는 div를 쓸 때는 <></>(fragment 문법) 감쌀 수 있다.
function Modal({title, date, cnt}){ //컴포넌트화
	return(
		<div className="modal">
			<div className="cntWrap">
				<p className="tit">{title}</p>
				<p className="date">{date}</p>
				<div className="cnt">{cnt}</div>
			</div>
		</div>
	)
}

function App() {
	//state는 변수 대신 쓰는 데이터 저장공간, useState를 사용해서 써야 함
	//let [state데이터, state데이터변경함수] = useState('내용');
	//let [글제목, 글제목변경] = useState('제목');
	let [title, setTitle] = useState(['제목입니다.', '가나다', 'ABCD', '1234']);
	let [date, setDate] = useState(['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04']);
	let [cnt, setCnt] = useState('내용');
	let [like, setLike] = useState([0,0,0,0]); //배열로 초기화
	let [modal, setModal] = useState([false,false,false,false]); //배열로 초기화
	let [value, setValue] = useState('');

	function changeTitle(){
		let newArray = [...title]; //title를 복사해서 수정
		newArray[0] = '바뀐제목';
		setTitle(newArray);
	}

	//map();
	//1. array에 담긴 자료의 수만큼 함수 안의 코드를 실행
	//2. 함수의 파라미터는 array안에 있던 자료
	//3. return에 적은걸 array에 담음
	// [1,2,3].map(function(a){ //익명함수 사용
	// 	return '12345' -> array에 '12345'를 담고 얘를 3번 반복해줌
	// }) -> array에 3개의 자료가 있으니까 3번 반복해줌

	//function toggleModal(index){
	//	let modalCopy = [...modal];
	//	modalCopy = modalCopy.map((_, i) => i === index); // 클릭한 모달 외에 다른 모달이 열려 있으면 다른 모달 닫기
	//	modalCopy[index] = !modalCopy[index]; //클릭한 모달 토글
	//	setModal(modalCopy);
	//}

	function toggleModal(index){ //모달 토글 + 다른 모달이 열려 있으면 그 모달 닫기
		let modalCopy = modal.map((isOpen, i) => {
			return i === index ? !isOpen : false;
		});
		setModal(modalCopy);
	}
	
	return (
		<div className="App">
			<div className="Title"><div>Dev Blog</div></div>
			<div className="Contents">
				<div className="list">
					<button type="button" className="btn-border" onClick={
						changeTitle
					}>Reset</button>
					{
						title.map(function(a, i){ //파라미터는 2개까지 넣을 수 있다, a=array안에 있는 데이터, i=반복문이 돌 때마다 0부터 1씩 증가하는 정수
							return (
								<div className="item" key={i}>
									<p className="tit" onClick={()=>toggleModal(i)}>{title[i]}</p>
									<button type="button" className="like" onClick={()=>{
										let copy = [...like];
										copy[i] = copy[i] + 1;
										setLike(copy);
									}}>💜 {like[i]}</button>
									<p className="date">{date[i]}</p>
									<div className="cnt">{cnt}</div>
									{modal[i] && <Modal title={title[i]} date={date[i]} cnt={cnt} />}
									<button type="button" className="btn-under" onClick={()=>{
										let copyTitle = [...title];
										copyTitle.splice(i, 1);
										setTitle(copyTitle);
									}}>삭제</button>
								</div>
							)
						})
					}
					<div className="write">
						<input type="text" className="txtBx" placeholder="내용 입력" onChange={(e) => {
							setValue(e.target.value); 
							console.log(value);
						}}/>
						<button type="button" className="btn-full" onClick={()=>{
							let titleCopy = [...title];
							titleCopy.unshift(value);
							setTitle(titleCopy)
						}}>저장</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;